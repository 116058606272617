import { useMemo } from "react";
import { useConfigSlice } from "../redux/config-slice";
import { useTranslation } from "react-i18next";

export const useConfig = () => {
  const { remoteConfig, isLoading: isRemoteConfigLoading } = useConfigSlice();
  const {
    i18n: { language },
  } = useTranslation();
  // const { i18n } = useTranslation();
  // const logo =
  //   i18n?.language === "ar"
  //     ? remoteConfig?.settings?.general_setting?.store_logo_ar
  //     : remoteConfig?.settings?.general_setting?.store_logo_en;

  const {
    payment_methods_online,
    payment_methods_wallet,
    online_method_required_card,
    enable_loyalty_points,
    enable_referral,
    enable_return_order,
    show_checkbox_conditions,
    force_select_default_locations,
    show_postal_code,
    update_type,
    update_message,
    update_image,
    update_url,
    languages,
    title,
    footer,
    description,
    keywords,
    app_name,
    logo,
    footer_logo,
    fav_icon,
    settings,
    default_country_id,
    ministry_commission,
    site_commission,
    store_name_en,
    store_name_ar,
    address_en,
    address_ar,
    phone_number,
    email,
    map_url,
    ios_app_link,
    android_app_link,
    store_logo_en,
    store_footer_logo_en,
    store_logo_ar,
    store_footer_logo_ar,
    facebook,
    twitter,
    youtube,
    linkedin,
    instagram,
    snapchat,
    whatsapp,
    default_location,
    currency_symbol,
    default_country_code,
    enable_services_page,
    default_service_id,
    services_screen_slider,
    services_screen_bottom_slider,
    verification_methods_sms,
    verification_methods_whatsapp,
    payment_methods_cash,
  } = useMemo(() => {
    return {
      payment_methods_online: !!remoteConfig?.payment_methods_online,
      payment_methods_wallet: !!remoteConfig?.payment_methods_wallet,
      online_method_required_card: !!remoteConfig?.online_method_required_card,
      enable_loyalty_points: !!remoteConfig?.enable_loyalty_points,
      enable_referral: !!remoteConfig?.enable_referral,
      enable_return_order: !!remoteConfig?.enable_return_order,
      show_checkbox_conditions: !!remoteConfig?.show_checkbox_conditions,
      force_select_default_locations:
        !!remoteConfig?.force_select_default_locations,
      show_postal_code: !!remoteConfig?.show_postal_code,
      update_type: remoteConfig?.update_type,
      update_message: remoteConfig?.update_message,
      update_image: remoteConfig?.update_image,
      update_url: remoteConfig?.update_url,
      languages: remoteConfig?.languages,
      title: remoteConfig?.title,
      footer: remoteConfig?.footer,
      description: remoteConfig?.description,
      keywords: remoteConfig?.keywords,
      app_name: remoteConfig?.app_name,
      logo: remoteConfig?.logo,
      footer_logo: remoteConfig?.footer_logo,
      fav_icon: remoteConfig?.fav_icon,
      settings: remoteConfig?.settings,
      default_country_id: remoteConfig?.default_country_code,

      ministry_commission: remoteConfig?.settings?.Tax?.ministry_comission || 0,
      site_commission: remoteConfig?.settings?.Tax?.site_comission || 0,

      store_name_en: remoteConfig?.settings?.general_setting?.store_name_en,
      store_name_ar: remoteConfig?.settings?.general_setting?.store_name_ar,
      address_en: remoteConfig?.settings?.general_setting?.address_en,
      address_ar: remoteConfig?.settings?.general_setting?.address_ar,
      phone_number: remoteConfig?.settings?.general_setting?.phone_number,
      email: remoteConfig?.settings?.general_setting?.email,
      map_url: remoteConfig?.settings?.general_setting?.map_url,
      ios_app_link: remoteConfig?.settings?.general_setting?.ios_app_link,
      android_app_link:
        remoteConfig?.settings?.general_setting?.android_app_link,
      store_logo_en: remoteConfig?.settings?.general_setting?.store_logo_en,
      store_footer_logo_en:
        remoteConfig?.settings?.general_setting?.store_footer_logo_en,
      store_logo_ar: remoteConfig?.settings?.general_setting?.store_logo_ar,
      store_footer_logo_ar:
        remoteConfig?.settings?.general_setting?.store_footer_logo_ar,

      facebook: remoteConfig?.settings?.social_media?.Facebook,
      twitter: remoteConfig?.settings?.social_media?.Twitter,
      youtube: remoteConfig?.settings?.social_media?.Youtube,
      linkedin: remoteConfig?.settings?.social_media?.Linkedin,
      instagram: remoteConfig?.settings?.social_media?.Instagram,
      snapchat: remoteConfig?.settings?.social_media?.Snapchat,
      whatsapp: remoteConfig?.settings?.social_media?.Whatsapp,

      default_location: remoteConfig?.default_location,

      currency_symbol: remoteConfig?.currency_symbol,
      default_country_code: remoteConfig?.default_country_code,
      enable_services_page: remoteConfig?.enable_services_page,
      default_service_id: remoteConfig?.default_service_id,
      services_screen_slider: remoteConfig?.services_screen_slider,
      services_screen_bottom_slider:
        remoteConfig?.services_screen_bottom_slider,
      verification_methods_sms: remoteConfig?.verification_methods_sms,
      verification_methods_whatsapp:
        remoteConfig?.verification_methods_whatsapp,
      payment_methods_cash: remoteConfig?.payment_methods_cash,
    };
  }, [remoteConfig]);

  return {
    isRemoteConfigLoading,

    payment_methods_online,
    payment_methods_wallet,
    online_method_required_card,
    enable_loyalty_points,
    enable_referral,
    enable_return_order,
    show_checkbox_conditions,
    force_select_default_locations,
    show_postal_code,
    update_type,
    update_message,
    update_image,
    update_url,
    languages,
    title,
    footer,
    description,
    keywords,
    app_name,
    logo,
    footer_logo,
    fav_icon,
    settings,
    default_country_id,
    ministry_commission,
    site_commission,
    store_name_en,
    store_name_ar,
    address_en,
    address_ar,
    phone_number,
    email,
    map_url,
    ios_app_link,
    android_app_link,
    store_logo_en,
    store_footer_logo_en,
    store_logo_ar,
    store_footer_logo_ar,
    facebook,
    twitter,
    youtube,
    linkedin,
    instagram,
    snapchat,
    whatsapp,
    default_location,
    currency_symbol,
    default_country_code,
    enable_services_page,
    default_service_id,
    services_screen_slider,
    services_screen_bottom_slider,
    verification_methods_sms,
    verification_methods_whatsapp,
    payment_methods_cash,
    store_name: language === "ar" ? store_name_ar : store_name_en,
  };
};
